
import Vue, { PropType } from "vue";

export type AttendanceSummaryFixFormHeaderType = {
  readonly value: string;
  readonly text: string;
  readonly icon: string;
  readonly suffix: string;
};

export default Vue.extend({
  name: "AttendanceSummaryFixForm",
  props: {
    value: {
      type: Object as PropType<any>,
      required: true
    },
    header: {
      type: Array as PropType<any[]>,
      required: true
    }
  },
  computed: {
    inputValue: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    workTimeSuffix(index: number): string {
      if (this.header[index].value === "worktime") {
        return this.value.minDispFlag == 0 ? "時間" : "分";
      }
      return this.header[index].suffix;
    }
  }
});
